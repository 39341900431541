<template>
  <div>
    <b-navbar toggleable="md" type="light" variant="light" fixed="top">
      <b-navbar-brand :href="frontendUrl">
        <font-awesome-icon :icon="['fas', 'home']" class="mr-1" />내 집 마련의 꿈
        <span style="font-size: 1rem;">ft. 그만 검색하고 싶다...</span>
      </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
import configService from '@/services/configService';

export default {
  name: 'NavBar',
  computed: {
    frontendUrl() {
      return configService.get('frontendUrl');
    }
  }
};
</script>
