<template>
  <div class="page-error">
    <p>Woops, error occurred ¯\_(ツ)_/¯</p>
  </div>
</template>

<script>
export default {
  name: 'Error'
};
</script>
