<template>
  <div>
    <div class="box-container box-container-filters">
      <div class="box-row box-title">
        Filters
        <span v-if="true">
          ({{ properties && properties.pagination ? properties.pagination.total_rows : 0 }} properties)
        </span>
        <div class="box-collapse-button-wrapper" v-if="optionCollapsed">
          <button type="button" class="btn btn-link btn-change-filter" @click="setOptionCollapsed">
            Change filter
          </button>
        </div>
      </div>
      <div class="box-row-wrapper" v-if="!loadingSearchOptions">
        <div class="box-row box-row-options-summary" v-if="optionCollapsed" v-html="searchParamsSummary"></div>
        <div class="box-row box-row-options" v-if="!optionCollapsed">
          <div
            class="box-column box-column-option"
            v-for="column in searchOptions"
            v-bind:key="column.type"
            v-bind:class="'box-column-' + column.type"
          >
            <div class="column-title">{{ column.label }}</div>
            <div class="column-select">
              <multiselect
                :value="selectedSearchOptions[column.type]"
                @input="callSelectedSearchOptions"
                :options="column.options"
                track-by="name"
                label="name"
                :id="column.type"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="true"
                :show-labels="true"
                :searchable="column.type === 'suburb' ? true : false"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} selected</span>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="box-column box-column-option">
            <b-form-checkbox
              id="checkbox-show-deleted"
              v-model="rawShowDeleted"
              name="showDeleted"
              value="true"
              unchecked-value="false"
            >
              Show deleted property
            </b-form-checkbox>
          </div>
        </div>
        <div class="box-row box-row-buttons">
          <b-button-group size="sm">
            <b-button variant="secondary" @click="shareUrl">Share</b-button>
            <b-button variant="info" @click="addRealestateUrl">Add URL</b-button>
            <b-button variant="warning" @click="showAllProperties">Show all</b-button>
            <b-button variant="primary" @click="callSearchProperties">Search</b-button>
          </b-button-group>
        </div>
        <b-modal id="add-realestate-url-modal" v-b-modal.modal-xl hide-header @ok="handlePropertyURLModalOK">
          <b-form-group
            id="realestate-url-form-group"
            label="Enter realestate URL:"
            label-for="realestate-url-input"
            description="i.e. https://www.realestate.com.au/property-apartment-vic-southbank-132768450"
          >
            <b-form-input id="realestate-url-input" v-model="propertyUrl" type="url"></b-form-input>
          </b-form-group>
        </b-modal>
      </div>

      <div class="box-row box-row-loading" v-if="loadingSearchOptions">
        <grid-loader :loading="true"></grid-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import bitlyService from '@/services/bitlyService';
import util from '@/helpers/util';

export default {
  name: 'SearchOptions',
  computed: {
    ...mapState('property', ['loadingSearchOptions', 'searchParams', 'currentPage', 'showDeleted']),
    ...mapGetters('property', ['searchOptions', 'searchParamsSummary', 'selectedSearchOptions', 'properties'])
  },
  data() {
    return {
      optionCollapsed: true,
      propertyUrl: '',
      shortenUrl: '',
      rawShowDeleted: false
    };
  },
  methods: {
    ...mapActions('alert', ['success', 'error']),
    ...mapActions('property', [
      'updateSelectedSearchOptions',
      'setCurrentPage',
      'setShowDeleted',
      'searchProperties',
      'resetSelectedSearchOptions',
      'fetchNewProperty'
    ]),
    callSelectedSearchOptions(selectedSearchOptions, type) {
      this.updateSelectedSearchOptions({ type, selectedSearchOptions });
    },
    setOptionCollapsed() {
      this.optionCollapsed = !this.optionCollapsed;
    },
    callSearchProperties() {
      this.optionCollapsed = true;
      this.setCurrentPage({ newPage: 1 });
      this.searchProperties();
    },
    showAllProperties() {
      this.optionCollapsed = true;
      this.resetSelectedSearchOptions();
      this.searchProperties();
    },
    addRealestateUrl() {
      this.$bvModal.show('add-realestate-url-modal');
    },
    handlePropertyURLModalOK(evt) {
      evt.preventDefault();

      if (this.propertyUrl === '' || util.isURL(this.propertyUrl) === false) {
        this.error({
          showType: 'toast',
          position: 'bottom-end',
          title: 'Error',
          text: 'Please enter valid property URL.'
        });
      } else if (
        this.propertyUrl.startsWith('https://m.realestate.com.au/property') === false &&
        this.propertyUrl.startsWith('https://www.realestate.com.au/property') === false
      ) {
        this.error({
          showType: 'toast',
          position: 'bottom-end',
          title: 'Error',
          text: 'Please enter only realestate URL.'
        });
      } else {
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('add-realestate-url-modal');
        });

        this.success({
          showType: 'toast',
          position: 'bottom-end',
          title: 'Fetching...',
          text: 'New property has been added. Please wait to be loaded. It may take more than a minute.'
        });
        this.fetchNewProperty({ propertyUrl: this.propertyUrl });
      }

      this.propertyUrl = '';
    },
    async shareUrl() {
      const currentUrl = window.location.href;

      this.$swal({
        title: 'Share this page!',
        confirmButtonText: 'Click here to copy URL',
        onBeforeOpen: async () => {
          return bitlyService.shortenUrl(currentUrl).then(async shortenUrl => {
            this.shortenUrl = shortenUrl;
          });
        },
        preConfirm: () => {
          this.copyShareUrl();
        }
      });
    },
    copyShareUrl() {
      this.$copyText(this.shortenUrl).then(
        _e => {
          this.success({
            showType: 'toast',
            position: 'bottom-end',
            title: 'Copied!',
            text: `The URL '${this.shortenUrl}' is copied.`
          });
        },
        _e => {
          this.error({
            showType: 'toast',
            position: 'bottom-end',
            title: 'Error...',
            text: 'Error occurred while copy URL.'
          });
        }
      );
    },
    async updateQueryString() {
      this.$router
        .replace({
          query: {
            searchParams: JSON.stringify(this.searchParams),
            page: this.currentPage,
            showDeleted: this.showDeleted
          }
        })
        .catch(_err => {});
    }
  },
  watch: {
    searchParams() {
      this.updateQueryString();
    },
    currentPage() {
      this.updateQueryString();
    },
    showDeleted() {
      this.rawShowDeleted = this.showDeleted;
    },
    rawShowDeleted() {
      this.setShowDeleted({ newShowDeleted: this.rawShowDeleted });
      this.updateQueryString();
    }
  }
};
</script>

<style lang="scss">
.box-container-filters {
  border: 1px solid #eee;
}

.box-container {
  display: flex;
  flex-direction: column;
}

.v-spinner {
  margin: 30px auto;
}

.multiselect__tags {
  max-height: 40px;
  overflow: hidden;
}

.box-row-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
}

.box-collapse-button-wrapper {
  float: right;
}

.btn-change-filter {
  color: white;
  text-decoration: underline;
  padding: 0;
  margin: 0 10px;
}

.btn-change-filter:hover {
  color: #efefef;
}

.box-row-options-summary {
  padding: 5px;
  font-size: 0.9rem;
}

.box-row-options-summary ul {
  margin: 0;
}

.box-column-option {
  padding: 5px;
  flex: 0 1 25%;
}

.box-row-buttons {
  text-align: right;
  padding: 0 5px 5px 0;
}

.box-title {
  background: #1e90ff;
  color: #fefefe;
  padding: 5px;
  text-transform: uppercase;
}

.modal-label {
  line-height: 0.9rem;
}

.help-text {
  font-size: 0.7rem;
}

@media screen and (max-width: 800px) {
  .box-column-option {
    flex: 0 1 33.33%;
  }
}

@media screen and (max-width: 600px) {
  .box-column-option {
    flex: 0 1 50%;
  }
}

@media screen and (max-width: 400px) {
  .box-column-option {
    flex: 0 1 100%;
  }
}
</style>
