import axios from 'axios';
import * as vueConfig from '../../vue.config';

class ConfigService {
  constructor() {
    this.config = {};
  }

  async loadConfig() {
    return axios.get(`${vueConfig.publicPath}static/config.${window.location.hostname}.json`).then(response => {
      this.config = response.data;
      return response.data;
    });
  }

  set(key, value) {
    this.config[key] = value;
  }

  get(key) {
    return this.config[key];
  }
}

export default new ConfigService();
