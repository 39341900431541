<template>
  <div id="app">
    <nav-bar />
    <b-container fluid>
      <router-view />
      <footer-bar />
    </b-container>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
  metaInfo: {
    title: 'App',
    titleTemplate: '%s | Frontend',
    meta: []
  },
  components: {
    NavBar,
    FooterBar
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 3.5rem;
}

@import '../src/assets/css/custom.css';
</style>
