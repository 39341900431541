<template>
  <div class="page-home">
    <search-option />
    <property-list />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import SearchOption from '@/components/SearchOption.vue';
import PropertyList from '@/components/PropertyList.vue';

export default {
  name: 'home',
  components: { SearchOption, PropertyList },
  metaInfo: {
    title: 'My realestate list for dream of having sweet home...',
    meta: [
      {
        name: 'description',
        content:
          'Fully customised realestate information with more transportation information. Not developed for public.'
      }
    ]
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('property', ['loading'])
  },
  methods: {
    ...mapActions('property', [
      'getSearchOptions',
      'searchProperties',
      'setSearchParams',
      'setCurrentPage',
      'setShowDeleted',
      'applySearchParams'
    ])
  },
  mounted() {
    let searchParams = {};
    let page = 1;
    let showDeleted = false;
    if (this.$route.query.searchParams) {
      try {
        searchParams = JSON.parse(this.$route.query.searchParams);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error on parsing search params');
      }
    }
    this.setSearchParams({ searchParams });

    if (this.$route.query.page) {
      page = this.$route.query.page;
    }

    this.setCurrentPage({ newPage: page });

    if (this.$route.query.showDeleted) {
      showDeleted = this.$route.query.showDeleted;
    }

    this.setShowDeleted({ newShowDeleted: showDeleted });

    // Get all search options

    this.getSearchOptions().then(() => {
      // And then get properties
      this.applySearchParams({ searchParams }).then(() => {
        this.searchProperties().then(() => {});
      });
    });
  }
};
</script>
