import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/Home.vue';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL || '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }
  ]
});

router.beforeEach((_to, _from, next) => {
  next();
});

export default router;
