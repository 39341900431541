import axios from 'axios';
import configService from '@/services/configService';

export default {
  async getSearchOptions() {
    const url = `${configService.get('apiUrl')}/search-option`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async searchProperties({ searchParams, page, showDeleted }) {
    return axios
      .get(
        `${configService.get('apiUrl')}/search?searchParams=${JSON.stringify(searchParams)}&page=${page ||
          1}&showDeleted=${showDeleted || false}`
      )
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async fetchNewProperty({ propertyUrl }) {
    return axios
      .get(`${configService.get('apiUrl')}/process?url=${propertyUrl}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
