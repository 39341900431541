import Vue from 'vue';

const state = {
  icon: null,
  position: null,
  title: null,
  text: null
};

const actions = {
  success({ commit }, { showType, position = 'bottom-end', title, text }) {
    commit('success', { showType, position, title, text });
  },
  info({ commit }, { showType, position = 'bottom-end', title, text }) {
    commit('info', { showType, position, title, text });
  },
  error({ commit }, { showType, position = 'bottom-end', title, text }) {
    commit('error', { showType, position, title, text });
  },
  setMessage({ commit }, { type, message }) {
    commit('setMessage', { type, message });
  },
  clear({ commit }) {
    commit('clear');
  }
};

const getters = {
  errorMessages: state => {
    if (state.icon === 'error' && state.text !== '') {
      return state.text;
    }
    return '';
  },
  successMessages: state => {
    if (state.icon === 'success' && state.text !== '') {
      return state.text;
    }
    return '';
  }
};

const displayToast = ({ icon, position, title, text }) => {
  Vue.swal({
    toast: true,
    position,
    showConfirmButton: false,
    timer: 3000,
    icon,
    title,
    text
  });
};

const mutations = {
  success(state, { showType, position, title, text }) {
    state.icon = 'success';
    state.position = position;
    state.title = title;
    state.text = text;
    if (showType === 'toast') {
      displayToast({ icon: state.icon, position: state.position, title: state.title, text: state.text });
    }
  },
  info(state, { showType, position, title, text }) {
    state.icon = 'info';
    state.position = position;
    state.title = title;
    state.text = text;
    if (showType === 'toast') {
      displayToast({ icon: state.icon, position: state.position, title: state.title, text: state.text });
    }
  },
  error(state, { showType, position, title, text }) {
    state.icon = 'error';
    state.position = position;
    state.title = title;
    state.text = text;
    if (showType === 'toast') {
      displayToast({ icon: state.icon, position: state.position, title: state.title, text: state.text });
    }
  },
  clear(state) {
    state.icon = null;
    state.position = null;
    state.title = null;
    state.text = null;
  },
  setMessage(state, { type, message }) {
    state.icon = type;
    state.text = message;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
