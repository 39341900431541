<template>
  <div class="property-wrapper" ref="printMe">
    <div class="property-num">#{{ property.row_num }}</div>
    <div class="property-in-market-badge" v-if="realestate.status === 'in-market'">In Market</div>
    <div class="property-sold-badge" v-if="realestate.status === 'sold'">Sold</div>
    <div class="property-deleted-badge" v-if="property.is_deleted === 1">Deleted</div>
    <div class="property-summary">
      <div>
        <font-awesome-icon :icon="['fas', 'bed']" />
        {{ realestate.bedrooms }}
        <font-awesome-icon :icon="['fas', 'bath']" />
        {{ realestate.bathrooms }}
        <font-awesome-icon :icon="['fas', 'car']" />
        {{ realestate.parkingSpaces }}
      </div>
      <div v-if="realestate.priceValue > 0">
        <font-awesome-icon :icon="['fas', 'receipt']" />&nbsp;{{
          realestate.priceValue
            | currency({
              symbol: '$',
              thousandsSeparator: ',',
              fractionCount: 0,
              fractionSeparator: '.',
              symbolPosition: 'front',
              symbolSpacing: false
            })
        }}
      </div>
      <div v-if="route.nearByStop !== null && route.nearByStop !== 'undefined'">
        <font-awesome-icon :icon="['fas', 'train']" />&nbsp;{{ route.nearByStop }}
      </div>
      <div v-if="route.transportToCityDuration !== null">
        <font-awesome-icon :icon="['fas', 'city']" />&nbsp;{{ route.transportToCityDuration }} to city
      </div>
      <div v-if="school.primary.name && school.primary.rank">
        <font-awesome-icon :icon="['fas', 'school']" />&nbsp;Primary in rank
      </div>
      <div v-if="school.secondary.name && school.secondary.rank">
        <font-awesome-icon :icon="['fas', 'school']" />&nbsp;Secondary in rank
      </div>
    </div>
    <b-carousel id="carousel-1" controls indicators style="text-shadow: 1px 1px 2px #333">
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="(image, index) in realestate.images"
        v-bind:key="`image-${property.id}-${index}`"
        :img-src="image"
      ></b-carousel-slide>
    </b-carousel>

    <p class="full-address">
      <a :href="'https://href.li/?' + property.property_url" target="_blank">{{ realestate.addressFull }}</a>
    </p>

    <div class="property-price-summary">
      <span class="price-display">{{ realestate.priceDisplay }}</span>
      <span class="property-status">
        {{ realestate.status | statusText }} -
        {{ realestate.propertyType }}
      </span>
      <span v-if="realestate.soldDate" class="property-sold-date">{{ realestate.soldDate }}</span>
    </div>

    <div class="property-links">
      <span class="view-google-map">
        <a :href="'https://href.li/?' + googleMapURL" target="_blank">View Google Map</a> |
        <a :href="'https://href.li/?' + measurementURL" target="_blank">View property measurement</a>
      </span>
    </div>

    <div class="property-summary-table-wrapper">
      <table class="property-summary-table" width="100%" border="1">
        <tr class="property-summary-header">
          <td>
            <strong>{{ realestate.propertyType }}</strong>
          </td>
          <td>Agent Price</td>
          <td>Land Size</td>
          <td>Median Price</td>
        </tr>
        <tr>
          <td>
            <font-awesome-icon :icon="['fas', 'bed']" />
            &nbsp;
            {{ realestate.bedrooms }}
            &nbsp;
            <font-awesome-icon :icon="['fas', 'bath']" />
            &nbsp;
            {{ realestate.bathrooms }}
            &nbsp;
            <font-awesome-icon :icon="['fas', 'car']" />
            &nbsp;
            {{ realestate.parkingSpaces }}
          </td>
          <td>{{ realestate.priceDisplay }}</td>
          <td>{{ realestate.landSize | NAIfNull }}</td>
          <td>
            {{
              realestate.medianPrice
                | currency({
                  symbol: '$',
                  thousandsSeparator: ',',
                  fractionCount: 0,
                  fractionSeparator: '.',
                  symbolPosition: 'front',
                  symbolSpacing: false
                })
            }}
          </td>
        </tr>
        <tr class="property-summary-header">
          <td>Near by Stop</td>
          <td>Transport to City</td>
          <td>Driving to City</td>
          <td>Rental Yield</td>
        </tr>
        <tr>
          <td>{{ route.nearByStop }}</td>
          <td>{{ route.transportToCityShort }}</td>
          <td>{{ route.drivingToCityShort }}</td>
          <td>{{ realestate.rentalYield | percentage }}</td>
        </tr>
        <tr class="property-summary-header">
          <td colspan="2">Primary School</td>
          <td colspan="2">Secondary School</td>
        </tr>
        <tr>
          <td colspan="2">
            <template v-if="school.primary.name">
              {{ school.primary.name }} (Rank: <span v-if="school.primary.rank">{{ school.primary.rank }}</span>
              <span v-else>N/A</span>)
            </template>
            <template v-else>N/A</template>
          </td>
          <td colspan="2">
            <template v-if="school.secondary.name">
              {{ school.secondary.name }} (Rank: <span v-if="school.secondary.rank">{{ school.secondary.rank }}</span>
              <span v-else>N/A</span>)
            </template>
            <template v-else>N/A</template>
          </td>
        </tr>
      </table>
    </div>

    <div class="section-wrapper realestate-wrapper">
      <div class="section-title-wrapper">
        <span class="section-title">
          <a :href="'https://href.li/?' + realestate.realestateViewURL" target="_blank">realestate.com.au</a>
        </span>
        <br />
        <span
          v-if="realestate.lastUpdated"
          class="section-updated-at"
          :title="realestate.lastUpdated | moment('YYYY-MM-DD HH:mm:ss')"
          >Fetched {{ realestate.lastUpdated | moment('from', 'now') }}</span
        >

        <span class="ml-2 section-updated-at" :title="property.updated_at"
          >Updated {{ property.updated_at | moment('from', 'now') }}</span
        >
      </div>
      <div class="section-summary-wrapper">
        <span class="summary-label">Property Type:</span>
        <span class="summary-value">{{ realestate.propertyType }}</span> |
        <span class="summary-label">Price:</span>
        <span class="summary-value">{{ realestate.priceDisplay }}</span> |
        <span class="summary-label">Construction Status:</span>
        <span class="summary-value">{{ realestate.constructionStatus | capitalise }}</span>
        <br />
        <span class="summary-label">Beds:</span>
        <span class="summary-value">{{ realestate.bedrooms }}</span> |
        <span class="summary-label">Baths:</span>
        <span class="summary-value">{{ realestate.bathrooms }}</span> |
        <span class="summary-label">Car Spaces:</span>
        <span class="summary-value">{{ realestate.parkingSpaces }}</span>
        <br />
        <span class="summary-label">Statement Of Information:</span>
        <span class="summary-value">
          <a
            v-if="realestate.statementOfInformation"
            :href="'https://href.li/?' + realestate.statementOfInformation"
            target="_blank"
            >Click to Download</a
          >
          <span v-if="!realestate.statementOfInformation">N/A</span>
        </span>
      </div>
      <div class="section-description-wrapper">{{ realestate.description | truncate(500) }}</div>
      <!--
      <div class="section-suburb-wrapper">
        <div class="section-subtitle">{{ realestate.suburb }} Suburb View</div>
        <span class="summary-label">Median Price:</span>
        <span class="summary-value">
          {{
            realestate.medianPrice
              | currency({
                symbol: '$',
                thousandsSeparator: ',',
                fractionCount: 0,
                fractionSeparator: '.',
                symbolPosition: 'front',
                symbolSpacing: false
              })
          }}
        </span>
        |
        <span class="summary-label">Rental Yield:</span>
        <span class="summary-value">{{ realestate.rentalYield | percentage }}</span> |
        <span class="summary-label">Annual Growth:</span>
        <span class="summary-value">{{ realestate.annualGrowth | percentage | NAIfNull }}</span>
      </div>
      <div class="section-school-wrapper">
        <div class="section-subtitle">Schools</div>
        <span class="summary-label">Primary:</span>
        <span class="summary-value">{{ realestate.primarySchools }} school(s)</span> |
        <span class="summary-label">Secondary:</span>
        <span class="summary-value">{{ realestate.secondarySchools }} school(s)</span>
      </div>
      -->
    </div>
    <div class="section-wrapper onthehouse-wrapper">
      <div class="section-title-wrapper">
        <span class="section-title">
          <a
            :href="
              'https://href.li/?' +
              (onthehouse.onthehouseURL ? onthehouse.onthehouseURL : 'https://www.onthehouse.com.au/')
            "
            target="_blank"
            >onthehouse.com.au</a
          >
        </span>
      </div>
      <div class="section-summary-wrapper" v-if="onthehouse.onthehouseURL">
        <span class="summary-label">Type:</span>
        <span class="summary-value">{{ onthehouse.type }}</span> |
        <span class="summary-label">Year Built:</span>
        <span class="summary-value">{{ onthehouse.yearBuilt }}</span> |
        <span class="summary-label">Floor Size:</span>
        <span class="summary-value">{{ onthehouse.floorSize ? onthehouse.floorSize + '㎡' : 'N/A' }}</span> |
        <span class="summary-label">Land Size:</span>
        <span class="summary-value">{{ onthehouse.landSize ? onthehouse.landSize + '㎡' : 'N/A' }}</span>

        <div class="section-subtitle mt-2">Guesstimate</div>
        <span class="summary-label">Confidence:</span>
        <span class="summary-value">
          {{ onthehouse.guesstimateConfidence ? onthehouse.guesstimateConfidence : 'N/A' }}
        </span>
        |
        <span class="summary-label">Date:</span>
        <span class="summary-value">{{ onthehouse.guesstimateDate ? onthehouse.guesstimateDate : 'N/A' }}</span> |
        <span class="summary-label">Price:</span>
        <span class="summary-value">
          {{ onthehouse.guesstimatePriceText ? onthehouse.guesstimatePriceText : 'N/A' }}
        </span>

        <div class="section-subtitle mt-2">Last Sale</div>
        <span class="summary-label">Date:</span>
        <span class="summary-value">{{ onthehouse.lastSaleDate }}</span> |
        <span class="summary-label">Price:</span>
        <span class="summary-value">
          {{
            onthehouse.lastSalePrice
              | currency({
                symbol: '$',
                thousandsSeparator: ',',
                fractionCount: 0,
                fractionSeparator: '.',
                symbolPosition: 'front',
                symbolSpacing: false
              })
          }}
        </span>
      </div>
      <div class="section-summary-wrapper" v-if="!onthehouse.onthehouseURL">Not found</div>
    </div>
    <div class="section-wrapper speakingsame-wrapper">
      <div class="section-summary-wrapper">
        <div class="section-title-wrapper">
          <span class="section-title">
            <a
              :href="
                'https://href.li/?http://house.speakingsame.com/p.php?q=' + encodeURIComponent(realestate.addressFull)
              "
              target="_blank"
              >house.speakingsame.com</a
            >
          </span>
        </div>
        <div class="section-summary-wrapper" v-if="speakingsame">
          <div
            class="summary-value mb-2 ml-0"
            :style="{ 'white-space': 'pre-wrap' }"
            v-html="$options.filters.nl2br(speakingsame.summary)"
          ></div>
          <div
            class="summary-value ml-0"
            :style="{ 'white-space': 'pre-wrap' }"
            v-html="$options.filters.nl2br(speakingsame.medianPrice)"
          ></div>
        </div>
        <div class="section-summary-wrapper" v-if="!speakingsame"> Not found </div>
      </div>
    </div>
    <div class="section-wrapper realestate-wrapper">
      <div class="section-title-wrapper">
        <span class="section-title">School</span>
      </div>
      <div class="section-summary-wrapper">
        <div class="section-subtitle"
          ><a
            href="https://href.li/?https://bettereducation.com.au/school/Primary/vic/vic_top_primary_schools.aspx"
            target="_blank"
            >Primary School</a
          >
          (Rank: <span v-if="school.primary.rank">{{ school.primary.rank }}</span> <span v-else>Not in rank</span>)</div
        >
        <span class="summary-label">Name:</span>
        <span class="summary-value">
          <span v-if="school.primary.name">{{ school.primary.name }}</span>
          <span v-else>N/A</span> </span
        ><br />
        <template v-if="school.primary.score">
          <span class="summary-label">Score:</span>
          <span class="summary-value">{{ school.primary.score }}</span
          ><br />
          <span class="summary-label">Sector:</span>
          <span class="summary-value">{{ school.primary.sector }}</span>
        </template>
        <br />
        <div class="section-subtitle"
          ><a
            href="https://href.li/?https://bettereducation.com.au/school/secondary/vic/vic_top_secondary_schools.aspx"
            target="_blank"
            >Secondary School</a
          >
          (Rank: <span v-if="school.secondary.rank">{{ school.secondary.rank }}</span>
          <span v-else>Not in rank</span>)</div
        >
        <span class="summary-label">Name:</span>
        <span class="summary-value">
          <span v-if="school.secondary.name">{{ school.secondary.name }}</span>
          <span v-else>N/A</span> </span
        ><br />
        <template v-if="school.secondary.score">
          <span class="summary-label">Score:</span>
          <span class="summary-value">{{ school.secondary.score }}</span
          ><br />
          <span class="summary-label">Sector:</span>
          <span class="summary-value">{{ school.secondary.sector }}</span>
        </template>
      </div>
    </div>
    <div class="section-wrapper realestate-wrapper">
      <div class="section-title-wrapper">
        <span class="section-title">Routes</span>
      </div>
      <div class="section-summary-wrapper">
        <div class="section-subtitle">Near by Stop (Tram/Train)</div>
        <span class="summary-label">Time (Distance) | Stop Name:</span>
        <span class="summary-value">
          <a :href="'https://href.li/?' + route.nearByStopURL" target="_blank" v-if="route.nearByStopFullText">{{
            route.nearByStopFullText
          }}</a>
          <span v-else>N/A</span>
        </span>

        <div class="section-subtitle mt-2">Driving to City</div>
        <span class="summary-label">Time (Distance):</span>
        <span class="summary-value">
          <a :href="'https://href.li/?' + route.drivingToCityURL" target="_blank">{{ route.drivingToCityShort }}</a>
        </span>

        <div class="section-subtitle mt-2">Transportation To City</div>
        <template v-if="route.transportToCitySteps">
          <span class="summary-label">Time (Distance):</span>
          <span class="summary-value">
            <a :href="'https://href.li/?' + route.transportToCityURL" target="_blank">
              {{ route.transportToCityShort }}
            </a>
          </span>
          <br />
          <span class="summary-label">Travel Time:</span>
          <span class="summary-value">{{ route.transportToCityTravelTime }}</span>
          <br />
          <span class="summary-label"
            >Travel steps - {{ route.transportToCitySteps ? route.transportToCitySteps.length : 0 }} steps:</span
          >
          <span class="summary-value" v-if="route.transportToCitySteps.length > 0">{{
            route.transportToCitySteps | implode(' -> ')
          }}</span>
          <span class="summary-value" v-else>N/A</span>
        </template>
      </div>

      <button type="button" class="btn btn-block btn-outline-dark" @click="generateScreenshot"
        >Copy this property</button
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import util from '@/helpers/util';

export default {
  name: 'Property',
  props: ['property'],
  computed: {},
  data() {
    return {
      realestate: {},
      googleMapURL: null,
      measurementURL: null,
      onthehouse: {},
      speakingsame: null,
      school: { primary: {}, secondary: {} },
      route: {},
      generatingScreenshot: false,
      screenshot: null
    };
  },
  mounted() {
    const metaDisplay = JSON.parse(this.property.meta_display);

    this.realestate = metaDisplay.realestate;
    this.googleMapURL = metaDisplay.googleMapURL;
    this.measurementURL = metaDisplay.measurementURL;
    this.onthehouse = metaDisplay.onthehouse;
    this.speakingsame = !_.isEmpty(metaDisplay.speakingsame) ? metaDisplay.speakingsame : null;
    this.school = metaDisplay.school || { primary: {}, secondary: {} };
    this.route = metaDisplay.route;

    this.$on('copy-text', () => {
      this.copyScreenshot();
    });
  },
  methods: {
    async generateScreenshot() {
      if (!window.ClipboardItem) {
        // this.error({
        //   showType: 'toast',
        //   position: 'bottom-end',
        //   title: 'Error...',
        //   text: 'Your browser does not support to copy clipboard.'
        // });
        this.copyURL();
      } else {
        this.info({
          showType: 'toast',
          position: 'bottom-end',
          title: 'Generating...',
          text: 'Please wait for generating image.'
        });

        this.generatingScreenshot = true;
        if (!this.screenshot) {
          const el = this.$refs.printMe;
          // add option type to get the image version
          // if not provided the promise will return
          // the canvas.
          const options = {
            type: 'dataURL'
          };
          this.screenshot = await this.$html2canvas(el, options);
        }
        this.generatingScreenshot = false;
        this.copyScreenshot();
      }
    },
    copyURL() {
      let text = `${this.realestate.addressFull}\r\n`;
      text += `- Bedrooms: ${this.realestate.bedrooms}\r\n`;
      text += `- Bathrooms: ${this.realestate.bathrooms}\r\n`;
      text += `- Carpark: ${this.realestate.parkingSpaces}\r\n`;
      text += `- Price: ${this.realestate.priceDisplay}\r\n`;
      text += `- Near by stop: ${this.route.nearByStop}\r\n`;
      text += `- Transport to City: ${this.route.transportToCityShort}\r\n`;
      text += `- Driving to City: ${this.route.drivingToCityShort}\r\n`;
      if (this.realestate.statementOfInformation) {
        text += `- Statement of Information: ${this.realestate.statementOfInformation}\r\n`;
      }

      text += `\r\n`;
      text += `- Realestate: ${this.realestate.realestateViewURL}\r\n`;
      text += `- Google Map: ${this.googleMapURL}\r\n`;
      if (this.onthehouse.onthehouseURL) {
        text += `- Onthehouse: ${this.onthehouse.onthehouseURL}\r\n`;
      }
      text += `- Primary school rank: ${this.school.primary.rank}\r\n`;
      text += `- Secondary school rank: ${this.school.secondary.rank}\r\n`;

      this.$copyText(text).then(
        _e => {
          this.success({
            showType: 'toast',
            position: 'bottom-end',
            title: 'Copied!',
            text: 'Realestate URL has been copied.'
          });
        },
        _e => {
          this.error({
            showType: 'toast',
            position: 'bottom-end',
            title: 'Error...',
            text: 'Your browser does not support to copy clipboard.'
          });
        }
      );
    },
    copyScreenshot() {
      // Split the base64 string in data and contentType
      const block = this.screenshot.split(';');
      // Get the content type of the image
      const contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      // Convert it to a blob to upload
      const blob = util.b64toBlob(realData, contentType);

      navigator.clipboard
        .write([
          // eslint-disable-next-line no-undef
          new ClipboardItem(
            Object.defineProperty({}, blob.type, {
              value: blob,
              enumerable: true
            })
          )
        ])
        .then(() => {
          // do something
          this.success({
            showType: 'toast',
            position: 'bottom-end',
            title: 'Copied!',
            text: 'You can paste generated screenshot now.'
          });
        });
    },
    ...mapActions('alert', ['info', 'success', 'error'])
  },
  filters: {
    percentage(value) {
      if (value) {
        return `${value.toFixed(2)}%`;
      }
      return '';
    },
    truncate(text, length, clamp) {
      const newClamp = clamp || '...';
      const node = document.createElement('div');
      node.innerHTML = text;
      const content = node.textContent;
      return content.length > length ? content.slice(0, length) + newClamp : content;
    },
    implode(array, glue) {
      if (!array || array.length === 0) {
        return '';
      }
      return array.join(glue);
    },
    statusText(status) {
      if (status === 'in-market') {
        return 'In market';
      }

      if (status === 'sold') {
        return 'Sold';
      }
      return 'N/A';
    },
    NAIfNull(text) {
      if (text === null || text === '') {
        return 'N/A';
      }
      return text;
    },
    capitalise(text) {
      if (typeof text !== 'string') {
        return text;
      }
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    nl2br(text) {
      if (typeof text !== 'string') {
        return text;
      }
      return text.trim().replace(/(\r\n|\r|\n){2,}/g, '$1\n');
    }
  },
  watch: {}
};
</script>

<style lang="scss">
.full-address {
  margin: 5px 0 0 0;
  font-size: 1.3rem;
  color: #0010ff;
}

.pagination {
  justify-content: center;
}

.property-price-summary,
.property-links {
  font-size: 0.9rem;
}

.property-summary-table-wrapper {
  margin-top: 5px;
}

.property-summary-table {
  width: 100%;
}

.property-wrapper {
  position: relative;
}

.property-num {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  background: #7e56f7;
  padding: 0 10px;
  text-align: center;
}

.property-sold-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  background: #dc3545;
  padding: 0 10px;
  width: 150px;
  font-size: 0.8rem;
}

.property-in-market-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  background: #28a745;
  padding: 0 10px;
  width: 150px;
  font-size: 0.8rem;
}

.property-deleted-badge {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: 1;
  color: #dc3545;
  background: #ffc107;
  padding: 0 10px;
}

.property-summary {
  position: absolute;
  top: 19px;
  right: 0;
  z-index: 1;
  background: #fff;
  width: 150px;
  font-size: 0.8rem;
  padding: 0 10px;
}

.property-status {
  color: #17a2b8;
}

.property-summary-header {
  background-color: #eee;
}

.property-summary-table td {
  padding: 5px;
  font-size: 0.8rem;
  text-align: center;
  border: 1px solid #eee;
}

.section-wrapper {
  margin-top: 10px;
  font-size: 0.8rem;
}

.section-title {
  text-transform: uppercase;
  font-size: 1rem;
  color: #007bff;
}

.summary-label {
  font-weight: bold;
  color: #272727;
}

.summary-value {
  margin-left: 5px;
}

.section-description-wrapper {
  margin-top: 5px;
}

.section-suburb-wrapper {
  margin-top: 10px;
}

.section-subtitle {
  font-weight: bold;
  font-size: 0.8rem;
}
</style>
