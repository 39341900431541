import axios from 'axios';

class BitlyService {
  async shortenUrl(url) {
    const config = {
      headers: { Authorization: `Bearer 67157d00ca600a73f1a066331c5ad8bf842159f1`, 'Content-Type': 'application/json' }
    };
    const params = {
      long_url: url
    };

    return axios.post(`https://api-ssl.bitly.com/v4/shorten`, params, config).then(response => {
      this.shortenUrl = response.data.link;
      return response.data.link;
    });
  }
}

export default new BitlyService();
