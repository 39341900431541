import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common';
import alert from './modules/alert';
import property from './modules/property';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    alert,
    property
  },
  strict: true
});
