<template>
  <div>
    <div class="properties-wrapper" v-if="!loadingProperties">
      <div v-if="properties && properties.rows.length > 0">
        <div class="properties-pagination-wrapper">
          <b-pagination
            v-model="currentPage"
            :total-rows="properties.pagination.total_rows"
            :per-page="properties.pagination.page_size"
            first-number
            last-number
            @change="changePage"
          ></b-pagination>
        </div>
        <div class="properties-list-wrapper">
          <div
            class="property-list-wrapper"
            v-for="property in properties.rows"
            v-bind:key="property.id"
            :data-id="property.id"
          >
            <property :property="property"></property>
          </div>
        </div>
        <div class="properties-pagination-wrapper">
          <b-pagination
            v-model="currentPage"
            :total-rows="properties.pagination.total_rows"
            :per-page="properties.pagination.page_size"
            first-number
            last-number
            @change="changePage"
          ></b-pagination>
        </div>
      </div>
      <div v-else>
        <p class="text-danger">Not found any property. Please try with another option.</p>
      </div>
    </div>
    <div class="properties-wrapper" v-if="loadingProperties">
      <grid-loader :loading="true"></grid-loader>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import Property from '@/components/Property.vue';

export default {
  name: 'PropertyList',
  components: { Property },
  computed: {
    ...mapState('property', { loadingProperties: 'loadingProperties', stateCurrentPage: 'currentPage' }),
    ...mapGetters('property', ['properties'])
  },
  data() {
    return {
      currentPage: 1
    };
  },
  methods: {
    ...mapActions('property', ['setCurrentPage', 'searchProperties']),
    changePage(page) {
      this.setCurrentPage({ newPage: page });
      this.searchProperties();
    }
  },
  watch: {
    stateCurrentPage(newPage) {
      this.currentPage = newPage;
    }
  }
};
</script>

<style lang="scss">
.properties-wrapper {
  margin: 10px 0;
  padding: 10px 0;
  border-top: 1px solid #eee;
  // border-bottom: 1px solid #eee;
}

.property-list-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.properties-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property-list-wrapper {
  flex: 1 0 auto;
  padding: 10px;
  margin: 0.5%;
  border: 1px solid#eee;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .property-list-wrapper {
    width: 49%;
  }
}

@media screen and (min-width: 1200px) {
  .property-list-wrapper {
    width: 32%;
  }
}

.properties-pagination-wrapper {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
