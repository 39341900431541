import _ from 'lodash';
import propertyService from '@/services/propertyService';
import util from '@/helpers/util';

/**
 * searchOptions will have selected in option

searchOptions[type] should return selected=true options

when select an option, update selected for searchOptions[type]
 */

const state = {
  loadingSearchOptions: false,
  loadingProperties: false,
  searchOptions: null,
  searchParams: {},
  searchParamsSummary: '',
  currentPage: 1,
  showDeleted: false,
  properties: null
};

const actions = {
  getSearchOptions({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest', { type: 'loadingSearchOptions' });

    return new Promise((resolve, reject) => {
      propertyService
        .getSearchOptions()
        .then(response => {
          commit('setSearchOptions', { searchOptions: response.data.searchOptions });
          resolve();
        })
        .catch(e => {
          commit('requestFailed', { type: 'loadingSearchOptions' });
          dispatch('common/handleServiceException', { e }, { root: true });
          reject();
        });
    });
  },
  updateSelectedSearchOptions({ commit }, { type, selectedSearchOptions }) {
    commit('updateSelectedSearchOptions', { type, selectedSearchOptions });
  },
  resetSelectedSearchOptions({ commit }) {
    commit('resetSelectedSearchOptions');
    commit('setCurrentPage', { newPage: 1 });
  },
  searchProperties({ dispatch, commit, state }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest', { type: 'loadingProperties' });

    return new Promise((resolve, reject) => {
      propertyService
        .searchProperties({ searchParams: state.searchParams, page: state.currentPage, showDeleted: state.showDeleted })
        .then(response => {
          commit('setProperties', { properties: response.data.properties });
          resolve();
        })
        .catch(e => {
          commit('requestFailed', { type: 'loadingProperties' });
          dispatch('common/handleServiceException', { e }, { root: true });
          reject();
        });
    });
  },
  fetchNewProperty({ dispatch, commit }, { propertyUrl }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest', { type: 'loadingProperties' });

    propertyService
      .fetchNewProperty({ propertyUrl })
      .then(_response => {
        commit('resetSelectedSearchOptions');
        commit('setCurrentPage', { newPage: 1 });
        dispatch('searchProperties');
      })
      .catch(e => {
        commit('requestFailed', { type: 'loadingProperties' });
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  applySearchParams({ commit }, { searchParams }) {
    return new Promise(resolve => {
      _.forEach(searchParams, (searchParam, type) => {
        const newOptions = searchParam.reduce((acc, param) => {
          acc.push({
            name: param
          });
          return acc;
        }, []);
        commit('updateSelectedSearchOptions', { type, selectedSearchOptions: newOptions });
      });

      resolve();
    });
  },
  setSearchParams({ commit }, { searchParams }) {
    commit('setSearchParams', { searchParams });
  },
  setCurrentPage({ commit }, { newPage }) {
    commit('setCurrentPage', { newPage });
  },
  setShowDeleted({ commit }, { newShowDeleted }) {
    commit('setShowDeleted', { newShowDeleted });
  }
};

const getters = {
  searchOptions: state => {
    if (!state.searchOptions) {
      // Search option is not yet retrieved.
      return [];
    }

    // Transform search options
    return state.searchOptions.reduce((acc, option) => {
      const optionIndex = _.findIndex(acc, { type: option.type });
      let optionGroup = {};

      if (optionIndex === -1) {
        // If this is new option, define new option group
        optionGroup = {
          type: option.type,
          label: util.getSearchOptionLabel(option.type),
          options: []
        };
      } else {
        // If this is existing option, get option group
        optionGroup = acc[optionIndex];
      }

      // Add new option to option group
      optionGroup.options.push({
        name: option.option_name,
        value: option.option_value,
        selected: option.selected || false
      });

      if (optionIndex === -1) {
        // If this was new option, then push to accumulator
        acc.push(optionGroup);
      } else {
        // If it was existing, then replace
        acc[optionIndex] = optionGroup;
      }

      return acc;
    }, []);
  },
  selectedSearchOptions: state => {
    if (!state.searchOptions) {
      return [];
    }

    return state.searchOptions.reduce((acc, option) => {
      if (acc[option.type] === undefined) {
        acc[option.type] = [];
      }

      if (option.selected) {
        acc[option.type].push({
          name: option.option_name,
          value: option.option_value,
          selected: true
        });
      }

      return acc;
    }, []);
  },
  searchParamsSummary: state => {
    if (_.isEmpty(state.searchParams) === true) {
      return `<strong>Show all properties</strong><br />- Show deleted: ${state.showDeleted === true ? 'Yes' : 'No'}`;
    }

    let summary = '<strong>Current filters</strong><br /><ul>';
    _.forEach(state.searchParams, (options, type) => {
      summary += `<li>${util.getSearchOptionLabel(type)}: ${options.join(', ')}</li>`;
    });
    summary += `<li>Show deleted: ${state.showDeleted === true ? 'Yes' : 'No'}</li>`;
    summary += '</ul>';
    return summary;
  },
  properties: state => state.properties
};

const mutations = {
  startRequest(state, { type }) {
    state[type] = true;
  },
  requestFailed(state, { type }) {
    state[type] = false;
  },
  setSearchOptions(state, { searchOptions }) {
    state.loadingSearchOptions = false;
    state.searchOptions = searchOptions;
  },
  updateSelectedSearchOptions(state, { type, selectedSearchOptions }) {
    // Clear selected for existing search options
    state.searchOptions = state.searchOptions.map(option => {
      const newOption = option;
      if (option.type === type) {
        newOption.selected = false;
      }
      return newOption;
    });

    // Update selected from property search
    selectedSearchOptions.forEach(option => {
      const optionIndex = _.findIndex(state.searchOptions, {
        type,
        option_name: option.name
      });
      state.searchOptions[optionIndex].selected = true;
    });

    // Update search params
    state.searchParams = {};
    state.searchOptions.forEach(option => {
      if (option.selected === true) {
        if (state.searchParams[option.type] === undefined) {
          state.searchParams[option.type] = [];
        }

        state.searchParams[option.type].push(option.option_name);
      }
    });
  },
  resetSelectedSearchOptions(state) {
    // Clear selected for existing search options
    state.searchOptions = state.searchOptions.map(option => {
      const newOption = option;
      newOption.selected = false;
      return newOption;
    });

    state.searchParams = {};
  },
  setProperties(state, { properties }) {
    const newProperties = properties;
    state.loadingProperties = false;
    if (properties.rows) {
      let rowNum = properties.pagination.first_row_no;
      newProperties.rows = properties.rows.map(property => {
        const newProperty = property;
        newProperty.row_num = rowNum;
        rowNum -= 1;
        return newProperty;
      });
    }
    if (properties.pagination) {
      state.currentPage = +properties.pagination.page;
    }
    state.properties = newProperties;
  },
  setSearchParams(state, { searchParams }) {
    state.searchParams = searchParams;
  },
  setCurrentPage(state, { newPage }) {
    state.currentPage = newPage;
  },
  setShowDeleted(state, { newShowDeleted }) {
    state.showDeleted = newShowDeleted;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
