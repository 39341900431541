const getSearchOptionLabel = columnKey => {
  const columnLabels = {
    suburb: 'Suburb',
    state: 'State',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    carparks: 'Carparks',
    property_type: 'Type',
    status: 'Status',
    property_price: 'Price',
    suburb_median_price: 'Median Price',
    transportation_duration_to_city: 'Duration to City',
    transportation_nearby_stop: 'Nearby Stop',
    transportation_changes_to_city: 'Changes to City',
    driving_duration_to_city: 'Driving to City',
    distance_to_city: 'Distance to City',
    primary_school_rank: 'Primary School',
    secondary_school_rank: 'Secondary School'
  };
  return columnLabels[columnKey] || columnKey;
};

const isURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
};

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
const b64toBlob = (b64Data, rawContentType, rawSliceSize) => {
  const contentType = rawContentType || '';
  const sliceSize = rawSliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

module.exports = { getSearchOptionLabel, isURL, b64toBlob };
