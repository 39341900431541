import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSweetalert2 from 'vue-sweetalert2';
import '@sweetalert2/theme-dark/dark.css';
import Multiselect from 'vue-multiselect';
import VueCurrencyFilter from 'vue-currency-filter';
import VueClipboard from 'vue-clipboard2';
import VueHtml2Canvas from 'vue-html2canvas';
import VueMoment from 'vue-moment';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import GridLoader from 'vue-spinner/src/GridLoader.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faTrash,
  faPlus,
  faCheckSquare,
  faSquare,
  faBed,
  faBath,
  faCar,
  faTrain,
  faSchool,
  faCity,
  faReceipt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import Error from './components/Error.vue';
import ConfigService from './services/configService';

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2, {});
Vue.use(VueMoment);
Vue.use(VueCurrencyFilter);
Vue.use(VueClipboard);
Vue.use(VueHtml2Canvas);

library.add(
  faHome,
  faTrash,
  faPlus,
  faCheckSquare,
  faSquare,
  faBed,
  faBath,
  faCar,
  faTrain,
  faSchool,
  faCity,
  faReceipt
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);
Vue.component('grid-loader', GridLoader);

(async () => {
  try {
    await ConfigService.loadConfig().then(_config => {
      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app');
    });
  } catch (err) {
    new Vue({
      router,
      store,
      render: h => h(Error)
    }).$mount('#app');
  }
})();
